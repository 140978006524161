@font-face {
  font-family: 'Ginto Nord';
  src: url('../renderer-ui-library/assets/fonts/GintoNord/GintoNord-Regular.woff2')
    format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// These font-weight values seem wrong
@font-face {
  font-family: 'Ginto Nord';
  src: url('../renderer-ui-library/assets/fonts/GintoNord/GintoNord-Medium.woff2')
    format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ginto Nord';
  src: url('../renderer-ui-library/assets/fonts/GintoNord/GintoNord-Bold.woff2')
    format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Radiate Sans';
  src: url('../renderer-ui-library/assets/fonts/RadiateSans/RadiateSans-BoldSemiExpanded.otf')
    format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@import '../renderer-ui-library/base/zIndexes.scss';
@import './font-faces.scss';

html,
body {
  padding: 0;
  margin: 0;
  font-family: Inter, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.zsiq_custommain {
  z-index: $zIndex5 !important;
}
